import http from "@/common/js/http";

/**
 * 获取司机登录日志
 * @param data
 */
export const getDriverLoginLogApi = async (data) => {
    return await http(`/galaxyDriverApproveApi/getDriverLoginLog`, data, 'POST');
};


/**
 * 司机检查口罩操作记录列表
 * @param data
 */
export const getDriverCheckWearMaskListByDriverIdApi = async (data) => {
    return await http(`/galaxyDriverApproveApi/getDriverCheckWearMaskListByDriverId`, data, 'POST');
};


/**
 * 添加查询口罩请求
 * @param data
 */
export const addDriverCheckWearMaskApi = async (data) => {
    return await http(`/galaxyDriverApproveApi/addDriverCheckWearMask`, data, 'POST');
};

/**
 * 获取供应商列表
 */
export const getSupplierListByOrgInfoApi = () => http('/galaxySupplier/doGalaxyGetSupplierListByOrgInfo')

/**
 * 删除司机
 */
export const deleteInvalidDriverApi = (id) => http('/galaxyDriverApproveApi/deleteInvalidDriver', {id}, 'POST')

/**
 * 添加黑名单
 */

export const addBlackListApi = (form) => http('/galaxyDriverApproveApi/addBlackList', form, 'POST')
/**
 * 黑名单列表
 */

export const getBlackListApi = (form) => http('/galaxyDriverApproveApi/getBlackList', form, 'POST')
/**
 * 添加黑名单
 */

export const updateBlackListApi = (form) => http('/galaxyDriverApproveApi/updateBlackList', form, 'POST')

/**
 * 申请导出司机列表
 */

export const applyExportDriverListApi = (form) => http('/galaxyDriverApproveApi/doexportDriverInfo', form, 'POST')

/**
 * 申请导出车辆列表
 */

export const applyExportCarListApi = (form) => http('/galaxyDriverApproveApi/doExportCarInfo', form, 'POST')

/**
 * 车辆司机申请导出列表
 */

export const driverAndCarExportListApi = (form) => http('/galaxyDriverApproveApi/exportInfoList', form, 'POST')

/**
 * 更新导出记录
 */

export const updateDriverAndCarExportInfoApi = (form) => http('/galaxyDriverApproveApi/updateExportInfo', form, 'POST')

/**
 * 下载导出记录
 */

export const downloadDriverAndCarExportApi = (form) => http('/galaxyDriverApproveApi/download', form, 'POST')

/**
 * 不合规司机车辆列表
 */

export const getNotQualifiedDriverApi = (form) => http('/galaxyDriverApproveApi/getNotQualifiedDriver', form, 'POST')
